<template>
  <div class="main-view-padding has-header" ref="mainView">
    <div class="header main" v-header-position-lock>
      <div class="align-left">
        <dateselectionheader :disableArrows="importStage == 2" :label="dateDisplay" @PrevDate="modDate('sub')" @NextDate="modDate('add')" />
      </div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons" />
      </div>
      <div class="align-right"></div>
    </div>
    <div style="display: flex; align-items: center; font-size: 32px; text-align: left; color: #e51550; border-bottom: 1px solid #ccc; padding: 5px; font-weight: 400; margin-bottom: 15px;">
      Monats-Durchschnittswerte
    </div>

    <div class="card" style="width: 500px; max-height: 200px;" v-if="loading != 'finished'">
      <div class="card-body">
        <div style="font-weight:bold;">Bitte warten</div>

        <div v-if="loading == 'starting'">Daten werden geladen...</div>
        <div v-if="loading == 'loadingEmployeePreferences'">Mitarbeiter Abwesenheiten werden geladen...</div>
        <div v-if="loading == 'loadingEmploymentModels'">Beschäftigungsmodelle werden geladen...</div>
        <div v-if="loading == 'loadingStores'">Filialen werden geladen...</div>
        <div v-if="loading == 'loadingEmployees'">Mitarbeiter werden geladen...</div>
        <div v-if="loading == 'loadingEmployeeMonthlyRecords'">Monats-Durchschnittswerte werden geladen...</div>
        <div v-if="loading == 'loadingEmployeeMonthlyRecordsFromMD'">Monats-Durchschnittswerte werden von MeinDienstplan geladen...</div>

        <div v-if="loading == 'savingCSVImport'">CSV Daten werden gespeichert...</div>

        <div v-if="loading == 'errorLoadingEmployeePreferences'">Fehler beim Laden der Mitarbeiter Abwesenheiten</div>
        <div v-if="loading == 'errorLoadingEmploymentModels'">Fehler beim Laden der Beschäftigungsmodelle</div>
        <div v-if="loading == 'errorLoadingStores'">Fehler beim Laden der Filialen</div>
        <div v-if="loading == 'errorLoadingEmployees'">Fehler beim Laden der Mitarbeiter</div>
        <div v-if="loading == 'errorLoadingEmployeeMonthlyRecords'">Fehler beim Laden der Monats-Durchschnittswerte</div>
        <div v-if="loading == 'errorLoadingEmployeeMonthlyRecordsFromMD'">Fehler beim Laden der Monats-Durchschnittswerte von MeinDienstplan</div>

        <div v-if="loading == 'finished'">Daten wurden erfolgreich geladen...</div>

        <div>
          <img v-bind:src="loadingImage" style="width: 200px; max-height: 40px;"/>
        </div>
      </div>
    </div>


    <!-- regular view -->
    <div v-if="loading == 'finished' && importStage !== 2" style="display: flex; flex-direction: column; justify-content: center; align-items: center; max-width: 1200px; margin: auto;">
      <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 20px; flex-wrap: wrap;">
        <div v-for="store in stores" v-bind:key="store.id"
             style="color: white; margin: 5px; font-size: 16px; border: #333 1px solid; cursor: pointer; min-width: 120px; max-width: calc(100% - 10px);"
             @click="store.selected = !store.selected">
          <div :class="store.selected ? 'selected' : 'unselected'"
               style="padding: 5px 10px; color: white; border: #333 1px solid; cursor: pointer;">
            <strong style="cursor: pointer;">{{ store.name }}</strong>
          </div>
          <div style="padding: 2px 5px; color: #333; border: #333 1px solid; cursor: pointer;">
            <small v-if="store.selected" style="cursor: pointer;">ausgewählt</small>
            <small v-if="!store.selected" style="cursor: pointer;">nicht ausgewählt</small>
          </div>
        </div>
      </div>

      <div style="display:flex; justify-content:center; align-items:center; max-width: 1200px;">
        <table class="table table-sm" style="">
          <thead style="font-size: 16px;">
            <tr>
              <th class="small text-left" v-on:click="sortBy('mdId')">MD ID <i class="fas" v-bind:class="(sortingColumn == 'mdId' && sortingDirection == 'desc') ? 'fa-sort-down' : ((sortingColumn == 'mdId' && sortingDirection == 'asc') ? 'fa-sort-up' : '')"/></th>
              <th class="small text-left" v-on:click="sortBy('personnelId')">Personalnummer <i class="fas" v-bind:class="(sortingColumn == 'personnelId' && sortingDirection == 'desc') ? 'fa-sort-down' : ((sortingColumn == 'personnelId' && sortingDirection == 'asc') ? 'fa-sort-up' : '')"/></th>
              <th class="small text-left">Geburtsdatum</th>
              <th class="small text-left">Beschäftigungsmodell</th>
              <th class="small text-left" v-on:click="sortBy('name')">Name <i class="fas" v-bind:class="(sortingColumn == 'name' && sortingDirection == 'desc') ? 'fa-sort-down' : ((sortingColumn == 'name' && sortingDirection == 'asc') ? 'fa-sort-up' : '')"/></th>
              <th class="small text-left" v-on:click="sortBy('averageHours')">∅ Std. <i class="fas" v-bind:class="(sortingColumn == 'averageHours' && sortingDirection == 'desc') ? 'fa-sort-down' : ((sortingColumn == 'averageHours' && sortingDirection == 'asc') ? 'fa-sort-up' : '')"/></th>
              <th class="small text-left" v-on:click="sortBy('averagePayment')">∅ EUR <i class="fas" v-bind:class="(sortingColumn == 'averagePayment' && sortingDirection == 'desc') ? 'fa-sort-down' : ((sortingColumn == 'averagePayment' && sortingDirection == 'asc') ? 'fa-sort-up' : '')"/></th>
              <th class="small text-left"></th>
              <th></th>
            </tr>
          </thead>
          <tr v-for="employee in filteredEmployees" :key="employee.id" style="font-size: 16px;">
            <td class="small text-left text-muted">{{ employee.id }}</td>
            <td class="small text-left text-muted">{{ employee.mdPersonnelId }}</td>
            <td class="small text-left text-muted">{{ formatDate(employee.dob) }}</td>
            <td class="small text-left text-muted">{{ employmentModels[employee.employmentModelId] ? employmentModels[employee.employmentModelId].name : '' }}</td>
            <td class="small text-left"><strong>{{ employee.lastName }}</strong> {{ employee.firstName }}</td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="employee.averageHours"
                    @input="sanitizeInput($event, employee, 'averageHours')"
                    @keyup.enter="saveEmployee(employee)"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="employee.averagePayment"
                    @input="sanitizeInput($event, employee, 'averagePayment')"
                    @keyup.enter="saveEmployee(employee)"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>
            <td class="small text-center" style="font-size: 15px; padding:5px 10px; cursor:default;">
              <!-- Check if both values match the original values to determine validity -->
              <i v-if="employee.saveRunning" class="fas fa-spinner-third fa-spin"></i>
              <i v-if="!employee.saveRunning && employee.averageHours === employee.originalAverageHours && employee.averagePayment === employee.originalAveragePayment" class="fas fa-check-circle" style="cursor:default; color:#777;"></i>
              <i v-if="!employee.saveRunning && (employee.averageHours !== employee.originalAverageHours || employee.averagePayment !== employee.originalAveragePayment)" class="fas fa-circle-xmark" style="cursor:default; color:#e51550;"></i>
            </td>
            <td class="small">
              <!-- Conditionally render the save button based on the values matching or not -->
              <button type="button" class="btn btn-secondary btn-sm"
                      v-if="!employee.saveRunning"
                      :class="{'disabled': employee.averageHours === employee.originalAverageHours && employee.averagePayment === employee.originalAveragePayment}"
                      style="max-width: 100px; font-size: 13px; padding:2px 5px; border:solid #ccc 1px; height:24px; background-color: employee.averageHours === employee.originalAverageHours && employee.averagePayment === employee.originalAveragePayment ? '#ccc' : ''"
                      @click="saveEmployee(employee)"
                      :disabled="employee.averageHours === employee.originalAverageHours && employee.averagePayment === employee.originalAveragePayment"
                      tabindex="-1">
                  <i class="fas fa-floppy-disk"></i> Speichern
              </button>
            </td>

          </tr>
        </table>
      </div>
    </div>

    <!-- csv import view -->
    <div v-if="loading == 'finished' && importStage == 2" style="display: flex; flex-direction: column; justify-content: center; align-items: center; max-width: 1200px; margin: auto;">
      <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 20px; flex-wrap: wrap;">
        <h3>CSV Import - {{ importStore.name ? importStore.name : "Store " + importStore }}</h3>
      </div>


      <div style="display:flex; justify-content:center; align-items:center; max-width: 1200px;">
        <table class="table table-sm" style="">

          <!-- Imported Employees in store without warning -->
          <tr v-show="importedEmployeesInStore.length > 0" class="importedEmployeesInStore">
            <th colspan="7" class="small text-left"><b>Folgende Mitarbeiter aus {{ importStore.name ? importStore.name : "Store " + importStore }} konnten aus der CSV Datei erfolgreich ausgelesen werden.</b></th>
          </tr>
          <tr v-show="importedEmployeesInStore.length > 0" class="importedEmployeesInStore">
            <th class="small text-left">MD ID</th>
            <th class="small text-left">Personalnummer</th>
            <th class="small text-left">Geburtsdatum</th>
            <th class="small text-left">Beschäftigungsmodell</th>
            <th class="small text-left">Name</th>
            <th class="small text-left">∅ Std.</th>
            <th class="small text-left">∅ EUR</th>
          </tr>
          <tr v-for="importedEmployee in importedEmployeesInStore" :key="importedEmployee.employee.id" style="font-size: 16px;" class="importedEmployeesInStore">
            <td class="small text-left text-muted">{{ importedEmployee.employee.id }}</td>
            <td class="small text-left text-muted">{{ importedEmployee.employee.mdPersonnelId }}</td>
            <td class="small text-left text-muted">{{ formatDate(importedEmployee.employee.dob) }}</td>
            <td class="small text-left text-muted">{{ employmentModels[importedEmployee.employee.employmentModelId] ? employmentModels[importedEmployee.employee.employmentModelId].name : '' }}</td>
            <td class="small text-left"><strong>{{ importedEmployee.employee.lastName }}</strong> {{ importedEmployee.employee.firstName }}</td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="importedEmployee.hours"
                    @input="sanitizeInput($event, importedEmployee, 'hours')"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="importedEmployee.payment"
                    @input="sanitizeInput($event, importedEmployee, 'payment')"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>

          </tr>
          <tr v-show="importedEmployeesInStore.length > 0"><td colspan="7" style="height: 20px; border-top: 0px;"></td></tr>

          <!-- Imported Employees in store without warning but with averages set already -->
          <tr v-show="importedEmployeesInStoreWithAverages.length > 0" class="importedEmployeesInStoreWithAverages">
            <th colspan="7" class="small text-left"><b>Folgende ausgelesene Mitarbeiter aus {{ importStore.name ? importStore.name : "Store " + importStore }} haben bereits Werte hinterlegt.</b></th>
          </tr>
          <tr v-show="importedEmployeesInStoreWithAverages.length > 0" class="importedEmployeesInStoreWithAverages">
            <th class="small text-left">MD ID</th>
            <th class="small text-left">Personalnummer</th>
            <th class="small text-left">Geburtsdatum</th>
            <th class="small text-left">Beschäftigungsmodell</th>
            <th class="small text-left">Name</th>
            <th class="small text-left">∅ Std.</th>
            <th class="small text-left">∅ EUR</th>
          </tr>
          <tr v-for="importedEmployee in importedEmployeesInStoreWithAverages" :key="importedEmployee.employee.id" style="font-size: 16px;" class="importedEmployeesInStoreWithAverages">
            <td class="small text-left text-muted">{{ importedEmployee.employee.id }}</td>
            <td class="small text-left text-muted">{{ importedEmployee.employee.mdPersonnelId }}</td>
            <td class="small text-left text-muted">{{ formatDate(importedEmployee.employee.dob) }}</td>
            <td class="small text-left text-muted">{{ employmentModels[importedEmployee.employee.employmentModelId] ? employmentModels[importedEmployee.employee.employmentModelId].name : '' }}</td>
            <td class="small text-left"><strong>{{ importedEmployee.employee.lastName }}</strong> {{ importedEmployee.employee.firstName }}</td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="importedEmployee.hours"
                    @input="sanitizeInput($event, importedEmployee, 'hours')"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="importedEmployee.payment"
                    @input="sanitizeInput($event, importedEmployee, 'payment')"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>

          </tr>
          <tr v-show="importedEmployeesInStoreWithAverages.length > 0"><td colspan="7" style="height: 20px; border-top: 0px;"></td></tr>

          <!-- Imported Employees with warning 3 (duplicates) -->
          <tr v-show="importedEmployeesWarning3.length > 0" class="importedEmployeesWarning3">
            <th colspan="7" class="small text-left"><b>Folgende Mitarbeiter haben mehrfach vorhandene Personalnummern. Bitte prüfen Sie die Personalnummern der Mitarbeiter.</b></th>
          </tr>
          <tr v-show="importedEmployeesWarning3.length > 0" class="importedEmployeesWarning3">
            <th class="small text-left">MD ID</th>
            <th class="small text-left">Personalnummer</th>
            <th class="small text-left">Geburtsdatum</th>
            <th class="small text-left">Beschäftigungsmodell</th>
            <th class="small text-left">Name</th>
            <th class="small text-left">∅ Std.</th>
            <th class="small text-left">∅ EUR</th>
          </tr>
          <tr v-for="importedEmployee in importedEmployeesWarning3" :key="importedEmployee.employee.id" style="font-size: 16px;" class="importedEmployeesWarning3">
            <td class="small text-left text-muted">{{ importedEmployee.employee.id }}</td>
            <td class="small text-left text-muted">{{ importedEmployee.employee.mdPersonnelId }}</td>
            <td class="small text-left text-muted">{{ formatDate(importedEmployee.employee.dob) }}</td>
            <td class="small text-left text-muted">{{ employmentModels[importedEmployee.employee.employmentModelId] ? employmentModels[importedEmployee.employee.employmentModelId].name : '' }}</td>
            <td class="small text-left"><strong>{{ importedEmployee.employee.lastName }}</strong> {{ importedEmployee.employee.firstName }}</td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="importedEmployee.hours"
                    @input="sanitizeInput($event, importedEmployee, 'hours')"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="importedEmployee.payment"
                    @input="sanitizeInput($event, importedEmployee, 'payment')"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>

          </tr>
          <tr v-show="importedEmployeesWarning3.length > 0"><td colspan="7" style="height: 20px; border-top: 0px;"></td></tr>

          <!-- Store Emps, which are not in import file -->
          <tr v-show="storeEmployeesWithoutImportEntry.length > 0" class="storeEmployeesWithoutImportEntry">
            <th colspan="7" class="small text-left"><b>Folgende Mitarbeiter aus {{ importStore.name ? importStore.name : "Store " + importStore }} wurden nicht in der CSV Datei gefunden.</b></th>
          </tr>
          <tr v-show="storeEmployeesWithoutImportEntry.length > 0" class="storeEmployeesWithoutImportEntry">
            <th class="small text-left">MD ID</th>
            <th class="small text-left">Personalnummer</th>
            <th class="small text-left">Geburtsdatum</th>
            <th class="small text-left">Beschäftigungsmodell</th>
            <th class="small text-left">Name</th>
            <th class="small text-left">∅ Std.</th>
            <th class="small text-left">∅ EUR</th>
          </tr>
          <tr v-for="employee, idx in storeEmployeesWithoutImportEntry" :key="employee.id + '_' + idx" style="font-size: 16px;" class="storeEmployeesWithoutImportEntry">
            <td class="small text-left text-muted">{{ employee.id }}</td>
            <td class="small text-left text-muted">{{ employee.mdPersonnelId }}</td>
            <td class="small text-left text-muted">{{ formatDate(employee.dob) }}</td>
            <td class="small text-left text-muted">{{ employmentModels[employee.employmentModelId] ? employmentModels[employee.employmentModelId].name : '' }}</td>
            <td class="small text-left"><strong>{{ employee.lastName }}</strong> {{ employee.firstName }}</td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="employee.averageHours"
                    @input="sanitizeInput($event, employee, 'averageHours')"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="employee.averagePayment"
                    @input="sanitizeInput($event, employee, 'averagePayment')"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>

          </tr>
          <tr v-show="storeEmployeesWithoutImportEntry.length > 0"><td colspan="7" style="height: 20px; border-top: 0px;"></td></tr>


          <tr>
            <td colspan="7" style="border-top: 0px">
              <button type="button" class="btn btn-secondary btn-sm"
                      v-if="!importLoading"
                      style="font-size: 13px; padding:2px 5px; border:solid #ccc 1px; height:30px;"
                      @click="saveImportedStoreEmployees()">
                  <i class="fas fa-floppy-disk"></i> Alle Mitarbeiter aus {{ importStore.name ? importStore.name : "Store " + importStore }} speichern!
              </button>
            </td>
          </tr>
          <tr><td colspan="7" style="height: 20px; border-top: 0px;"></td></tr>

          <!-- Imported Employees with warning 1 (not enough columns) or 2 (invalid float values) -->
          <tr v-show="importedEmployeesWarning1.length > 0" class="importedEmployeesWarning1">
            <th colspan="7" class="small text-left"><b>Folgende Einträge konnten in der CSV Datei nicht richtig gelesen werden. Bitte prüfen Sie die Zeilen.</b></th>
          </tr>
          <tr v-show="importedEmployeesWarning1.length > 0" class="importedEmployeesWarning1">
            <th class="small text-left">MD ID</th>
            <th class="small text-left">Personalnummer</th>
            <th class="small text-left">Geburtsdatum</th>
            <th class="small text-left">Beschäftigungsmodell</th>
            <th class="small text-left">Name</th>
            <th class="small text-left">∅ Std.</th>
            <th class="small text-left">∅ EUR</th>
          </tr>
          <tr v-for="importedEmployee, idx in importedEmployeesWarning1" :key="importedEmployee.personnelId + '_not_found_' + idx" style="font-size: 16px;" class="importedEmployeesWarning1">
            <td class="small text-left text-muted"></td>
            <td class="small text-left text-muted">{{ importedEmployee.personnelId }}</td>
            <td class="small text-left text-muted"></td>
            <td class="small text-left text-muted"></td>
            <td class="small text-left"></td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="importedEmployee.hours"
                    disabled
                    @input="sanitizeInput($event, importedEmployee, 'hours')"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="importedEmployee.payment"
                    disabled
                    @input="sanitizeInput($event, importedEmployee, 'payment')"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>

          </tr>
          <tr v-show="importedEmployeesWarning1.length > 0"><td colspan="7" style="height: 20px; border-top: 0px;"></td></tr>

          <!-- Imported Employees in differnet store without warning -->
          <tr v-show="importedEmployeesInDifferentStore.length > 0" class="importedEmployeesInDifferentStore">
            <th colspan="7" class="small text-left"><b>Folgende Mitarbeiter aus einem anderen Store wurden in der CSV Datei gefunden.</b></th>
          </tr>
          <tr v-show="importedEmployeesInDifferentStore.length > 0" class="importedEmployeesInDifferentStore">
            <th class="small text-left">MD ID</th>
            <th class="small text-left">Personalnummer</th>
            <th class="small text-left">Geburtsdatum</th>
            <th class="small text-left">Store</th>
            <th class="small text-left">Name</th>
            <th class="small text-left">∅ Std.</th>
            <th class="small text-left">∅ EUR</th>
          </tr>
          <tr v-for="importedEmployee in importedEmployeesInDifferentStore" :key="importedEmployee.employee.id" style="font-size: 16px;" class="importedEmployeesInDifferentStore">
            <td class="small text-left text-muted">{{ importedEmployee.employee.id }}</td>
            <td class="small text-left text-muted">{{ importedEmployee.employee.mdPersonnelId }}</td>
            <td class="small text-left text-muted">{{ formatDate(importedEmployee.employee.dob) }}</td>
            <td class="small text-left text-muted">{{ stores.find(s => s.id == importedEmployee.employee.storeId).name }}</td>
            <td class="small text-left"><strong>{{ importedEmployee.employee.lastName }}</strong> {{ importedEmployee.employee.firstName }}</td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="importedEmployee.hours"
                    disabled
                    @input="sanitizeInput($event, importedEmployee, 'hours')"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="importedEmployee.payment"
                    disabled
                    @input="sanitizeInput($event, importedEmployee, 'payment')"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>

          </tr>
          <tr v-show="importedEmployeesInDifferentStore.length > 0"><td colspan="7" style="height: 20px; border-top: 0px;"></td></tr>

          <!-- Imported Employees with warning 4 (no employee found) -->
          <tr v-show="importedEmployeesWarning4.length > 0" class="importedEmployeesWarning4">
            <th colspan="7" class="small text-left"><b>Folgende Einträge konnten keinem Mitarbeiter zugeordnet werden.</b></th>
          </tr>
          <tr v-show="importedEmployeesWarning4.length > 0" class="importedEmployeesWarning4">
            <th class="small text-left">MD ID</th>
            <th class="small text-left">Personalnummer</th>
            <th class="small text-left">Geburtsdatum</th>
            <th class="small text-left">Beschäftigungsmodell</th>
            <th class="small text-left">Name</th>
            <th class="small text-left">∅ Std.</th>
            <th class="small text-left">∅ EUR</th>
          </tr>
          <tr v-for="importedEmployee, idx in importedEmployeesWarning4" :key="importedEmployee.personnelId + '_not_found_' + idx" style="font-size: 16px;" class="importedEmployeesWarning4">
            <td class="small text-left text-muted"></td>
            <td class="small text-left text-muted">{{ importedEmployee.personnelId }}</td>
            <td class="small text-left text-muted"></td>
            <td class="small text-left text-muted"></td>
            <td class="small text-left"></td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="importedEmployee.hours"
                    disabled
                    @input="sanitizeInput($event, importedEmployee, 'hours')"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>
            <td class="small text-left">
              <input type="text" class="form-control form-control-sm"
                    v-model="importedEmployee.payment"
                    disabled
                    @input="sanitizeInput($event, importedEmployee, 'payment')"
                    placeholder="0.00" style="max-width: 100px; font-size: 15px; padding:1px 4px; border:solid #ccc 1px; height:24px;">
            </td>

          </tr>
          <tr v-show="importedEmployeesWarning4.length > 0"><td colspan="7" style="height: 20px; border-top: 0px;"></td></tr>


        </table>
      </div>

    </div>

    <!-- csv import modal -->
    <b-modal
      size="lg"
      class="import-modal"
      ref="modal-import"
      id="modal-import"
      v-model="importModal"
      hide-footer
      hide-header
    >
      <div style="text-align: center">
        <br/>
        <div style="margin: 0px 48px 8px 48px">
          <div v-if="importStage == 0" class="import-drop-zone">
            <h4>CSV hochladen</h4>
            <br/>
            <div
              @click="showFilePicker"
              id="dropzone"
              style="text-align:center; background: var(--contrast-1); padding: 100px 0px; border-radius: 25px; cursor: pointer;"
            >
              <i
                class="fal fa-4x fa-file-download"
                style="color: var(--contrast-5)"
              ></i>
              <br/><br/>
              <small style="color: var(--contrast-5); font-size: 11px"
              >Hier klicken oder eine Datei per Drag&Drop ablegen</small
              >
              <input
                id="fileImportInput"
                type="file"
                @change="fileSelected($event.target.files)"
                class="input-file"
              />
            </div>
            <div></div>
          </div>
          <div v-else-if="importStage == 1">
            <div v-if="[1].includes(importState.error)">
              <h4>Bitte wählen Sie genau eine Datei aus!</h4>
              <p></p>
              <br/>
              <button
                style="margin-top: 24px"
                class="button"
                v-on:click="hideImportModal(); openImportModal();"
              >
                Import erneut starten
              </button>
            </div>
            <div v-else-if="[2,3,5].includes(importState.error)">
              <h4>Bitte wählen Sie eine CSV Datei mit dem Format &lt;Storenummer&gt;_&lt;Monat&gt;_&lt;Jahr&gt;_&lt;Dateiname&gt;.csv aus!</h4>
              <p></p>
              <br/>
              <button
                style="margin-top: 24px"
                class="button"
                v-on:click="hideImportModal(); openImportModal();"
              >
                Import erneut starten
              </button>
            </div>
            <div v-else-if="[4].includes(importState.error)">
              <h4>Der in der Datei angegebene Store scheint nicht in Ihrer Company vorhanden zu sein.</h4>
              <h4>Bitte prüfen Sie den Dateinamen und versuchen Sie den Import erneut.</h4>
              <!-- <p>Sie können trotzdem versuchen die Durchschnittswerte zu importieren.</p> -->
              <br/>
              <!-- <button
                style="margin-top: 24px"
                class="button"
                v-on:click="parseImportFile([importState.file], true);"
              >
                Durchschnittswerte trotzdem importieren
              </button> -->
              <button
                style="margin-top: 24px"
                class="button"
                v-on:click="hideImportModal(); openImportModal();"
              >
                Import erneut starten
              </button>
            </div>
            <div v-else-if="[6].includes(importState.error)">
              <h4>Das in der Datei angegebene Monat und Jahr stimmt nicht mit dem aktuell ausgewähltem Datum überein.</h4>
              <p>Bitte ändern Sie das aktuell ausgewählte Monat oder importieren Sie eine andere Datei.</p>
              <br/>
              <button
                style="margin-top: 24px"
                class="button"
                v-on:click="hideImportModal(); openImportModal();"
              >
                Import erneut starten
              </button>
            </div>

          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import button from "../uicomponents/button.vue";
import moment from 'moment';

export default {
  components: { button },
  name: "AverageValues",
  data() {
    return {
      loading: 'loadingStores',
      loadingImage: '../img/loading2.svg',
      saves: false,
      stores: [],
      storesData: null,

      showHidden: false,

      employeeArray: [],

      employmentModels: {},

      sortingColumn: 'lastName',
      sortingDirection: 'asc',
      mdPersonnelIdsNumericOnly: true,

      selectedDate: this.$helpers.getMomentTZ().clone(),
      timeframeStart: this.$helpers.getMomentTZ().clone().startOf("month"),
      timeframeEnd: this.$helpers.getMomentTZ().clone().endOf("month"),

      employeeHasPreferenceMap: {},
      employeeMonthlyRecords: [],

      importLoading: false,
      importModal: false,
      importLoadingText: "",
      importFilePickerOpening: false,
      importStage: 0,
      importStore: null,
      importState: {
        error: 0,
        file: null,
        importFile: null,
        importPayload: [],
      },


      monthNames: {
        0: "Jänner",
        1: "Februar",
        2: "März",
        3: "April",
        4: "Mai",
        5: "Juni",
        6: "Juli",
        7: "August",
        8: "September",
        9: "Oktober",
        10: "November",
        11: "Dezember",
      },
    };
  },
  created: function () {
  },
  mounted: function () {
    if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
      this.refresh();
    }
    console.log("MOUNTED");
  },
  computed: {
    ...mapGetters([
      "companyId",
      "globalFilter"]),
    headerButtons() {
      let view = this;

      let state = {
        buttons: [],
        helperLinks: [
          {
            title: "Support erhalten",
            helperText:
              "Wenn Sie auf Ihre Frage keine Antwort im Hilfecenter finden, können Sie uns eine Support Anfrage stellen. Klicken Sie dazu einfach auf 'Support kontaktieren', geben Sie Ihre E-Mail Adresse ein, schreiben Sie eine detaillierte Zusammenfassung Ihres Problems inkl. der Daten Ihres Stores, laden Sie etwaige Anhänge (Bilder, etc.) hoch und klicken Sie auf 'Senden'. Wir sehen uns Ihr Problem an und melden uns so schnell wie möglich.",
            externalUrl:
              "https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/group/11",
            externalLabel: "Support kontaktieren",
            cancelLabel: "Schließen",
          }
        ]
      };
      state.buttons.push({
        type: "block",
        icon: "fa-refresh",
        disabled: view.loading != 'finished' || view.importStage == 2,
        click: () => {
          view.refresh();
        },
        tooltip: "Daten neu laden",
      });

      state.buttons.push({
        type: (view.showHidden) ? "block green" : "block red",
        icon: "fa-eye-slash",
        disabled: view.loading != 'finished' || view.importStage == 2,
        click: () => {
          view.showHidden = !view.showHidden;
        },
        tooltip: (view.showHidden) ? "Versteckte Mitarbeiter werden angezeigt" : "Versteckte Mitarbeiter werden ausgeblendet",
      });


      state.buttons.push({
        type: "block red",
        icon: "fa-cancel",
        show: view.importStage == 2,
        click: () => {
          view.importStage = 0;
          this.$nextTick(() => {
            view.$refs.mainView.scrollTop = 0;
          });
        },
        tooltip: "CSV Import abbrechen",
      });
      /*
      // Disabled, cause it lead to missunderstandings in Gerd Vogel Company. They pressed it, then saved and wondered why all values got changed.
      state.buttons.push({
        type: "block",
        icon: "fa-cloud-arrow-down",
        click: () => {
          view.loadAveragesFromMD();
        },
        tooltip: "Durchschnittswerte aus MeinDienstplan laden",
      });
      */
      state.buttons.push({
        type: "block",
        icon: "fa-file-csv",
        disabled: view.loading != 'finished' || view.importStage == 2,
        click: () => {
          view.openImportModal();
        },
        tooltip: "Durchschnittswerte aus CSV Datei importieren",
        helperText: "Mit dieser Funktion können Sie die Durchschnittswerte für Ihre Mitarbeiter aus einer CSV Datei importieren. "
                  + "Bitte beachten Sie, dass die Datei im Format &lt;Storenummer&gt;_&lt;Monat&gt;_&lt;Jahr&gt;_&lt;Dateiname&gt;.csv "
                  + "vorliegen muss. Die Datei muss 3 Spalten enthalten: Personalnummer, Durchschnittliche Stunden, Durchschnittlicher Lohn.",
        externalUrl: "https://meindienstplan.atlassian.net/servicedesk/customer/kb/view/2840592386",
      });
      state.buttons.push({
        type: "block",
        icon: "fa-save",
        disabled: view.loading != 'finished' || view.importStage == 2,
        click: () => {
          view.saveAllEmployees();
        },
        tooltip: "Alles speichern",
      });
      return state;
    },
    dateDisplay: function () {
      let view = this;
      return view.monthNames[view.timeframeStart.months()] + " " + view.timeframeStart.format("YYYY");
    },
    storesDataFiltered() {
      let filtered = [];
      console.log(this.storesData);
      console.log(this.selectedStores);
      this.storesData.forEach((s) => {
        /*if (
          this.selectedStores.filter((elem) => {
            return s.id == elem.id;
          }).length > 0
        )*/
        filtered.push(s);
      });
      return filtered;
    },
    filteredEmployees() {
      let enhancedStores = [...this.stores];

      // Filter logic including the store filter
      let filteredEmployees = this.employeeArray.filter(employee => {
        if(!this.showHidden){
          if(employee.isHidden){
            return false;
          }
        }
        const employeeStoreId = employee.storeId || 0;
        const isStoreSelected = enhancedStores.some(store => store.selected && store.id === employeeStoreId);

        return isStoreSelected;
      });

      // try to sort personnelId as Number - will sort as string if not all personnelIds are numbers
      let mdPersonnelIdsNumericOnly = true;
      if (this.sortingColumn == 'personnelId') {
        filteredEmployees.forEach(employee => {
          employee.mdPersonnelIdNumeric = 0;
          if (employee.mdPersonnelId != null && employee.mdPersonnelId != "" && !this.isNumeric(employee.mdPersonnelId)) {
            mdPersonnelIdsNumericOnly = false;
          } else if (employee.mdPersonnelId == null || employee.mdPersonnelId == "") {
            employee.mdPersonnelIdNumeric = Infinity;
          } else {
            employee.mdPersonnelIdNumeric = parseFloat(employee.mdPersonnelId);
          }
        });
      }

      return filteredEmployees.sort((a, b) => {
        if (this.sortingColumn == 'name') {
            if (a.lastName < b.lastName) return this.sortingDirection == 'asc' ? -1 : 1;
            if (a.lastName > b.lastName) return this.sortingDirection == 'asc' ? 1 : -1;
            if (a.firstName < b.firstName) return this.sortingDirection == 'asc' ? -1 : 1;
            if (a.firstName > b.firstName) return this.sortingDirection == 'asc' ? 1 : -1;
            return 0;
        } else if (this.sortingColumn == 'mdId') {
            if (a.id < b.id) return this.sortingDirection == 'asc' ? -1 : 1;
            if (a.id > b.id) return this.sortingDirection == 'asc' ? 1 : -1;
            return 0;
        } else if (this.sortingColumn == 'personnelId') {
            if (mdPersonnelIdsNumericOnly) {
              if (a.mdPersonnelIdNumeric < b.mdPersonnelIdNumeric) return this.sortingDirection == 'asc' ? -1 : 1;
              if (a.mdPersonnelIdNumeric > b.mdPersonnelIdNumeric) return this.sortingDirection == 'asc' ? 1 : -1;
            } else {
              if (a.mdPersonnelId < b.mdPersonnelId) return this.sortingDirection == 'asc' ? -1 : 1;
              if (a.mdPersonnelId > b.mdPersonnelId) return this.sortingDirection == 'asc' ? 1 : -1;
            }
            return 0;
        } else if (this.sortingColumn == 'averageHours') {
            if (parseFloat(a.averageHours) < parseFloat(b.averageHours)) return this.sortingDirection == 'asc' ? -1 : 1;
            if (parseFloat(a.averageHours) > parseFloat(b.averageHours)) return this.sortingDirection == 'asc' ? 1 : -1;
            return 0;
        } else if (this.sortingColumn == 'averagePayment') {
            if (parseFloat(a.averagePayment) < parseFloat(b.averagePayment)) return this.sortingDirection == 'asc' ? -1 : 1;
            if (parseFloat(a.averagePayment) > parseFloat(b.averagePayment)) return this.sortingDirection == 'asc' ? 1 : -1;
            return 0;
        } else {
            return 0;
        }
      });
    },
    importedEmployeesInStore() {
      return this.importState.importPayload.filter(importEmp => {
        return importEmp.warning == null && importEmp.employee
          ? importEmp.employee.storeId === this.importStore.id && importEmp.employee.averageHours == 0 && importEmp.employee.averagePayment == 0
          : false;
      });
    },
    importedEmployeesInStoreWithAverages() {
      return this.importState.importPayload.filter(importEmp => {
        return importEmp.warning == null && importEmp.employee
          ? importEmp.employee.storeId === this.importStore.id && (importEmp.employee.averageHours !== 0 || importEmp.employee.averagePayment !== 0)
          : false;
      });
    },
    importedEmployeesInDifferentStore() {
      return this.importState.importPayload.filter(importEmp => {
        return importEmp.warning == null && importEmp.employee
          ? importEmp.employee.storeId !== this.importStore.id
          : false;
      });
    },
    importedEmployeesWarning1() {
      let emps = this.importState.importPayload.filter(importEmp => importEmp.warning == 1 || importEmp.warning == 2);
      // console.log(emps);
      return emps;
    },
    importedEmployeesWarning3() {
      let emps = this.importState.importPayload.filter(importEmp => importEmp.warning == 3);
      // console.log(emps);
      return emps;
    },
    importedEmployeesWarning4() {
      let emps = this.importState.importPayload.filter(importEmp => importEmp.warning == 4);
      // console.log(emps);
      return emps;
    },
    storeEmployeesWithoutImportEntry() {
      let storeEmps = this.employeeArray.filter(emp => this.importStore ? emp.storeId === this.importStore.id : false);
      return storeEmps.filter(emp => {
        return !this.importState.importPayload.find(importEmp => importEmp.personnelId === emp.mdPersonnelId);
      });
    },
  },
  watch: {
    companyId: function (val, oldVal) {
      this.refresh();
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function (force = false) {
      let view = this;
      view.loading = 'starting';

      view.importLoading = false;
      view.importModal = false;
      view.importLoadingText = "";
      view.resetImportState();

      view.$helpers.GetStoresSettings(force).then((response) => {
        console.log("********************************");
        console.log(response);
        console.log("********************************");
        })
      .catch((error) => {
        console.log("ERROR");
      });

      view.loadEmploymentModels();
    },

    sortBy(column) {
      if (this.sortingColumn === column) {
        this.sortingDirection = this.sortingDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortingColumn = column;
        this.sortingDirection = 'asc';
      }
    },
    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },

    sanitizeInput(event, employee, property) {
      const regex = /^[0-9.,]+$/;
      let value = event.target.value;
      value = value.replace(/,/g, '.');
      if (!regex.test(value)) {
        value = value.replace(/[^0-9.]+/g, '');
      }
      this.$set(employee, property, value);
    },

    loadEmployeePreferencesFromMD: function () {
      let view = this;
      view.loading = 'loadingEmployeePreferences';
      let employeeHasPreferenceMap = this.$data.employeeHasPreferenceMap;
      let promise = new Promise(function (resolve, reject) {
        let params = {
          date: view.selectedDate.format("YYYY-MM-DD"),
        };
        view.$helpers
          .GetCall("/api/sec/proxy/md/employeepreferences", params)
          .then((response) => {
            for (let i in response.data) {
              let preference = response.data[i];
              if (preference.state != 1) continue; // Only Absences, we don't want to export wishes
              if (!preference.approved) continue; // Only export approved absences
              if (preference.remoteId == 0) continue; // Skip dummy
              view.$set(employeeHasPreferenceMap, preference.remoteId, true);
            }
            for (let employeeId in view.employees) {
              if (!(employeeId in employeeHasPreferenceMap)) {
                view.$set(employeeHasPreferenceMap, employeeId, false);
              }
            }
            view.loading = 'finished';
            resolve(employeeHasPreferenceMap);
          })
          .catch((e) => {
            view.loading = 'errorLoadingEmployeePreferences';
            reject(e);
          });
      });
      return promise;
    },



    loadEmploymentModels: function () {
      let view = this;
      view.loading = 'loadingEmploymentModels';
      let params = { companyId: view.$store.state.companyId };
      view.$helpers
        .GetCall("/api/sec/employmentModels", params)
        .then((response) => {
          let data = response.data.data;
          for (let i = 0; i < data.length; i++) {
            let item = {
              id: data[i].id,
              number: data[i].number,
              name: data[i].name,
              sorting: data[i].sorting,
              color: data[i].color,
            };
            view.employmentModels[item.id] = item;
          }
          view.loadEmployees(true);
        })
        .catch((e) => {
            view.loading = 'errorLoadingEmploymentModels';
          console.log(e);
        });
    },
    loadEmployeeMonthlyRecords(year, month) {
      let view = this;
      view.loading = 'loadingEmployeeMonthlyRecords';
      let url = `/api/sec/employee/averageRecords/get`;
      let data = {
        year: year,
        month: month,
        companyId: view.$store.state.companyId
      };

      this.axios({
        method: 'post',
            headers: { 'AUTHORIZATION': "Bearer " + view.$store.state.jwt },
            url: url,
            data: data
          }).then(response => {
            view.employeeMonthlyRecords = response.data && Array.isArray(response.data) ? response.data : [];

            view.employeeArray.forEach(employee => {
                let record = view.employeeMonthlyRecords.find(r => r.employeeId === employee.id);
                if (record) {
                  employee.saveRunning = false;
                    employee.originalAverageHours = record.averageHours;
                    employee.originalAveragePayment = record.averagePayment;
                    employee.averageHours = record.averageHours;
                    employee.averagePayment = record.averagePayment;
                } else {
                  employee.saveRunning = false;
                    employee.originalAverageHours = 0;
                    employee.originalAveragePayment = 0;
                    employee.averageHours = 0;
                    employee.averagePayment = 0;
                }
            });
          view.$forceUpdate();
            this.loadEmployeePreferencesFromMD();
        }).catch(function (error) {
          view.loading = 'errorLoadingEmployeeMonthlyRecords';
          console.error("Error fetching employee monthly records:", error);
      });
    },


    loadEmployees: function (force = false, employeeIdToSelect = null) {
      let view = this;
      view.loading = 'loadingEmployees';
      view.$helpers.GetEmployeeMap(force, true)
      .then((response) => {
          let empls = response;
          empls.sort((a, b) => {
            if (a.lastName < b.lastName) return -1;
            if (a.lastName > b.lastName) return 1;
            if (a.firstName < b.firstName) return -1;
            if (a.firstName > b.firstName) return 1;
            return 0;
          });
          this.sortingColumn = 'name';
          this.sortingDirection = 'asc';
          this.employeeArray = empls.map(employee => ({
              ...employee,
              averageHours: employee.averageHours || null,
              averagePayment: employee.averagePayment || null,
              valid: employee.valid || true
          }));
          //this.prepareStoreEmployees(empls, employeeIdToSelect, force);

          //this.loadingStage++;
          view.loadStoresForCompany();

      })
            .catch(error => {
              view.loading = 'errorLoadingEmployees';
                console.error("Error fetching employees:", error);
            });
    },



    saveEmployee(employee) {
      let view = this;
      let year = view.selectedDate.year();
      let month = view.selectedDate.month() + 1;
      let url = '/api/sec/employee/averageRecords/store?companyId=' + view.$store.state.companyId;
      const data = {
        year: year,
        month: month,
        employees: [
          {
            remoteId: employee.id,
            averagePayment: employee.averagePayment,
            averageHours: employee.averageHours
          }
        ]
      };
      employee.saveRunning = true;
          view.$forceUpdate();

      this.axios({
        method: 'post',
        headers: { 'AUTHORIZATION': "Bearer " + view.$store.state.jwt },
        url: url,
        data: data
      }).then(response => {
        employee.originalAverageHours = employee.averageHours;
        employee.originalAveragePayment = employee.averagePayment;
        employee.saveRunning = false;
        view.$forceUpdate();

        view.$helpers.success("Gespeichert", "Durchnittswerte wurden erfolgreich gespeichert.");
      }).catch(function (error) {
        console.log(error);
        employee.saveRunning = false;
          view.$forceUpdate();
        view.$helpers.error("Error", "Fehler beim Speichern der Durchschnittswerte.");
      });
    },

    saveAllEmployees(){
      let view = this;
      let year = view.selectedDate.year();
      let month = view.selectedDate.month() + 1;
      let url = '/api/sec/employee/averageRecords/store?companyId=' + view.$store.state.companyId;

      const data = {
        year: year,
        month: month,
        employees: [
        ]
      };

      let employeeIdsToSave = [];

      view.employeeArray.forEach(employee => {
        if(employee.originalAverageHours !== employee.averageHours || employee.originalAveragePayment !== employee.averagePayment){
          employee.saveRunning = true;
          data.employees.push({
            remoteId: employee.id,
            averagePayment: employee.averagePayment,
            averageHours: employee.averageHours
          });
          employeeIdsToSave.push(employee.id);
        }
      });

      view.$forceUpdate();

      this.axios({
        method: 'post',
        headers: { 'AUTHORIZATION': "Bearer " + view.$store.state.jwt },
        url: url,
        data: data
      }).then(response => {
        view.employeeArray.forEach(employee => {
          if(employeeIdsToSave.includes(employee.id)){
            employee.originalAverageHours = employee.averageHours;
            employee.originalAveragePayment = employee.averagePayment;
            employee.saveRunning = false;
          }
        });
        view.$forceUpdate();
        view.$helpers.success("Gespeichert", "Durchnittswerte wurden erfolgreich gespeichert.");
      }).catch(function (error) {
        console.log(error);
        view.employeeArray.forEach(employee => {
          if(employeeIdsToSave.includes(employee.id)){
            employee.saveRunning = false;
          }
        });
          view.$forceUpdate();
        view.$helpers.error("Error", "Fehler beim Speichern der Durchschnittswerte.");
      });
    },





    loadStoresForCompany: function () {
      let view = this;
      //view.loadStores = true;
      view.loading = 'loadingStores';
      let params = {};

      view.$helpers.GetExportDeselectedStores().then((deselectedStores) => {
        view.$helpers
          .GetCall("/api/sec/stores", params)
          .then((response) => {
            const storesData = response.data.data;
            const defaultSelectedValue = storesData.length > 3 ? false : true;

            view.stores = storesData.map(store => ({
              ...store,
              selected: defaultSelectedValue // Set the 'selected' property based on the condition
            }));

            // Here you decide the default selected state for "nicht kategorisiert"
            // Assuming you always want it as false based on your provided code
            view.stores.unshift({
              id: 0,
              name: "nicht kategorisiert",
              selected: storesData.length > 3 ? false : true // Keep this special store as not selected by default
            });

            view.$forceUpdate();
            this.loadEmployeeMonthlyRecords(view.selectedDate.year(), view.selectedDate.month() + 1);
          })
          .catch((e) => {
            //view.loadStores = false;
            //view.loading = false;
      view.loading = 'errorLoadingStores';
            console.log(e);
          });
      });
    },
    loadAveragesFromMD: function () {
      let view = this;
      view.loading = 'loadingEmployeeMonthlyRecordsFromMD';
      let promises = this.stores.map(store => {
        let from = moment(this.selectedDate).startOf('month').toISOString();
        let to = moment(this.selectedDate).endOf('month').toISOString();
        let params = {
          storeId: store.id,
          from: from,
          to: to
        };
        return view.$helpers.GetCall("/api/sec/proxy/md/monthly-records", params);
      });

      Promise.all(promises)
        .then(responses => {
          console.log("Responses: ", responses);
          responses.forEach((response, index) => {
            response.data.forEach(employee => {
              console.log("Search for employee: ", employee.remoteId, " in store: ", view.stores[index].name, " with id: ", view.stores[index].id);
              let storeEmployee = view.employeeArray.find(e => e.id === employee.remoteId);
              if (storeEmployee) {
                storeEmployee.averageHours = (employee.avgWorkTime/60.0).toFixed(4);
                storeEmployee.averagePayment = (employee.avgCentPerHours / 100.0).toFixed(4);
                // IF averageHours is different to the averageHours or averagePayment is different to the avgCentPerHours from the employee, set valid to false
                //storeEmployee.valid = true;
              }
            });
          });
          view.$forceUpdate();
          view.loading = 'finished';
        })
        .catch(error => {
          console.error('Error loading averages:', error);
          view.loading = 'errorLoadingEmployeeMonthlyRecordsFromMD';
        });
    },





    formatDate(date) {
        const parsedDate = moment(date);
        return parsedDate.isValid() ? parsedDate.format('DD.MM.YYYY') : "";
    },
    modDate: function (method) {
      let view = this;

      if (method == "add") {
        this.selectedDate.add(1, "months");
        this.timeframeStart = this.selectedDate.clone().startOf("month");
        this.timeframeEnd = this.selectedDate.clone().endOf("month");
        this.$forceUpdate();
      } else if (method == "sub") {
        this.selectedDate.subtract(1, "months");
        this.timeframeStart = this.selectedDate.clone().startOf("month");
        this.timeframeEnd = this.selectedDate.clone().endOf("month");
        this.$forceUpdate();
      }
      this.$helpers.SetExportSelectedColumns({ currentDate: this.$helpers.getMomentTZ(), date: this.selectedDate, });
      this.refresh(false, 5);
    },


    // ***************************************
    // CSV IMPORT
    // ***************************************

    resetImportState() {
      this.importFilePickerOpening = false;
      this.importStage = 0;
      this.importStore = null;
      this.importState = {
        error: 0,
        file: null,
        importFile: null,
        importPayload: [],
      };
    },
    openImportModal() {
      this.resetImportState();
      this.importLoading = false;
      this.importModal = true;
      this.$nextTick(function () {
        setTimeout(() => { // add short timeout to find dropzone after reopening model (element is not yet rendered due to animation ??)
          var dropZone = document.getElementById("dropzone");
          if (dropZone) {
            console.log(
              "Drop zone found, adding event listener for drag and drop"
            );
            dropZone.addEventListener("dragover", this.handleDragOver, false);
            dropZone.addEventListener("drop", this.filesDropped, false);
          }
        }, 100);
      });
    },
    hideImportModal() {
      this.importLoading = false;
      this.importModal = false;
    },
    showFilePicker() {
      if (!this.importFilePickerOpening) {
        console.log("Pick files with filepicker");
        this.importFilePickerOpening = true;
        setTimeout(() => {
          // debounce multiple clicks to avoid multiple subsequent file input dialogs
          this.importFilePickerOpening = false;
        }, 1000);
        document.getElementById("fileImportInput").click();
      }
    },
    fileSelected(files) {
      this.importFilePickerOpening = false;
      this.parseImportFile(files);
    },
    filesDropped(evt) {
      evt.stopPropagation();
      evt.preventDefault();

      var droppedFiles = evt.dataTransfer.files; // FileList Objekt
      // console.log(droppedFiles);
      this.parseImportFile(droppedFiles);
    },
    handleDragOver(evt) {
      evt.stopPropagation();
      evt.preventDefault();
      evt.dataTransfer.dropEffect = "copy";
    },
    parseImportFile(selectedFile, forceOtherStore = false) {
      // console.log(selectedFile);
      var dropZone = document.getElementById("dropzone");
      if (dropZone) {
        dropZone.removeEventListener("dragover", this.handleDragOver, false);
        dropZone.removeEventListener("drop", this.filesDropped, false);
      }
      this.importLoading = true;
      this.importStage = 1;
      if (selectedFile.length != 1) {
        this.importState.error = 1;
        console.log("not exactly one file selected", selectedFile);
      } else {
        this.importState.file = selectedFile[0];
        const file = selectedFile[0];

        let filename = file.name;
        let type = file.type;

        if (type !== "text/csv") {
          this.importState.error = 2;
          console.log("not a csv file", file);
          return;
        }

        let fileNameSplit = filename.split("_");
        if (fileNameSplit.length < 4) {
          this.importState.error = 3;
          console.log("not a valid filename", file);
          return;
        }

        let storeNumber = fileNameSplit[0];
        this.importStore = storeNumber;
        let foundPossibleStore = false;
        this.stores.forEach(store => {
          if ((store.storeNumber != null && store.storeNumber == storeNumber) || store.name.includes(storeNumber)) {
            this.importStore = store;
            foundPossibleStore = true;
          }
        });
        if (!foundPossibleStore && !forceOtherStore) {
          this.importState.error = 4;
          console.log("store not found", file);
          return;
        }

        let month = parseInt(fileNameSplit[1]);
        let year = parseInt(fileNameSplit[2]);
        if (month < 1 || month > 12 || year < 2000 || year > 2999) {
          this.importState.error = 5;
          console.log("month or year is not valid", file);
          return;
        }

        if (month != this.selectedDate.month() + 1 || year != this.selectedDate.year()) {
          this.importState.error = 6;
          console.log("month or year does not match selected date", file);
          return;
        }


        const reader = new FileReader();
        reader.onload = (event) => {
          const fileContent = event.target.result;
          const allLines = fileContent.split(/\r\n|\n/);
          // console.log(allLines);
          let allLineObjects = [];
          let firstLine = true;
          allLines.forEach(line => {
            line = line.trim();
            // console.log(line);

            let lineParts = line.split(";");

            let lineObject = {
              personnelId: lineParts[0],
              hours: 0.0,
              payment: 0.0,
              warning: null,
            };
            if (lineParts[0].length < 1) {
              firstLine = false;
              return;
            }
            if (lineParts.length < 3) {
              console.log("line does not have enough parts", line);
              lineObject.warning = 1;
              allLineObjects.push(lineObject);
              firstLine = false;
              return;
            }
            const isFloat = (value) => !isNaN(parseFloat(value));
            lineParts[1] = lineParts[1].replace(',', '.');
            lineParts[2] = lineParts[2].replace(',', '.');
            if (!isFloat(lineParts[1]) || !isFloat(lineParts[2])
              || parseFloat(lineParts[1]).toFixed(2) !== lineParts[1] || parseFloat(lineParts[2]).toFixed(2) !== lineParts[2]) {
              console.log("line does not have valid numbers", line);
              lineObject.warning = 2;
              if (!firstLine) {
                allLineObjects.push(lineObject);
              }
              firstLine = false;
              return;
            }
            lineObject.hours = parseFloat(lineParts[1]);
            lineObject.payment = parseFloat(lineParts[2]);
            allLineObjects.push(lineObject);
            firstLine = false;
          });
          // console.log(allLineObjects);
          this.importState.importPayload = allLineObjects;
          this.matchEmployees();
          this.importStage = 2;
          this.hideImportModal();
          this.$nextTick(() => {
            this.$refs.mainView.scrollTop = 0;
          });
        };

        reader.onerror = (event) => {
          alert(event.target.error.name);
        };

        reader.readAsText(file);
      }
    },
    matchEmployees() {
      let duplicateEntries = [];
      this.importState.importPayload.forEach(importEntry => {
        let employees = this.employeeArray.filter(e => !e.isHidden && e.mdPersonnelId == importEntry.personnelId);
        // multiple employees with same personnel id
        if (employees.length > 1) {
          // try to find the employee with the same store
          let storeEmployees = employees.filter(e => this.importStore ? e.storeId == this.importStore.id : false);
          // multiple employees with same personnel id and store or none in store
          if (storeEmployees.length > 1) {
            for (let i = 0; i < storeEmployees.length; i++) {
              let employee = storeEmployees[i];
              if (i > 0) {
                let duplicateEntry = JSON.parse(JSON.stringify(importEntry));
                duplicateEntry.employee = employee;
                duplicateEntry.warning = 3;
                duplicateEntries.push(duplicateEntry);
              } else {
                importEntry.employee = employee;
                importEntry.warning = 3;
              }
            }
          } else if (storeEmployees.length == 1) {
            importEntry.employee = storeEmployees[0];
          } else {
            for (let i = 0; i < employees.length; i++) {
              let employee = employees[i];
              if (i > 0) {
                let duplicateEntry = JSON.parse(JSON.stringify(importEntry));
                duplicateEntry.employee = employee;
                // duplicateEntry.warning = 3; // do not add warning for employees in different store for now
                duplicateEntries.push(duplicateEntry);
              } else {
                importEntry.employee = employee;
                // importEntry.warning = 3;
              }
            }
          }
        } else if (employees.length == 1) {
          importEntry.employee = employees[0];
        } else {
          importEntry.employee = null;
          importEntry.warning = 4;
        }
      });
      this.importState.importPayload = this.importState.importPayload.concat(duplicateEntries);
    },
    saveImportedStoreEmployees(){
      let view = this;
      let year = view.selectedDate.year();
      let month = view.selectedDate.month() + 1;
      let url = '/api/sec/employee/averageRecords/store?companyId=' + view.$store.state.companyId;

      view.loading = 'savingCSVImport';

      const data = {
        year: year,
        month: month,
        employees: [
        ]
      };

      // importedEmployeesInStore, importedEmployeesInStoreWithAverages, importedEmployeesWarning3, storeEmployeesWithoutImportEntry
      // console.log("importedEmployeesInStore", view.importedEmployeesInStore);
      // console.log("importedEmployeesInStoreWithAverages", view.importedEmployeesInStoreWithAverages);
      // console.log("importedEmployeesWarning3", view.importedEmployeesWarning3);
      // console.log("storeEmployeesWithoutImportEntry", view.storeEmployeesWithoutImportEntry);

      let employeesToSave = [];
      employeesToSave = employeesToSave.concat(view.importedEmployeesInStore);
      employeesToSave = employeesToSave.concat(view.importedEmployeesInStoreWithAverages);
      employeesToSave = employeesToSave.concat(view.importedEmployeesWarning3);
      employeesToSave = employeesToSave.concat(view.storeEmployeesWithoutImportEntry.map(emp => { return {employee: emp, hours: emp.averageHours, payment: emp.averagePayment, personnelId: emp.mdPersonnelId}}));

      let employeeIdsToSave = [];

      employeesToSave.forEach(emp => {
        // console.log(emp.employee);
        if(emp.employee.originalAverageHours !== emp.hours || emp.employee.originalAveragePayment !== emp.payment){
          data.employees.push({
            remoteId: emp.employee.id,
            averagePayment: parseFloat(""+emp.payment),
            averageHours: parseFloat(""+emp.hours)
          });
          employeeIdsToSave.push(emp.employee.id);
        }
      });

      this.axios({
        method: 'post',
        headers: { 'AUTHORIZATION': "Bearer " + view.$store.state.jwt },
        url: url,
        data: data
      }).then(response => {
        view.employeeArray.forEach(employee => {
          if(employeeIdsToSave.includes(employee.id)){
            let emp = employeesToSave.find(emp => emp.employee.id === employee.id);
            if (emp) {
              employee.averageHours = emp.hours;
              employee.originalAverageHours = emp.hours;
              employee.averagePayment = emp.payment;
              employee.originalAveragePayment = emp.payment;
              employee.saveRunning = false;
            }
          }
        });
        view.importStage = 0;
        view.loading = 'finished';
        view.$forceUpdate();
        this.$nextTick(() => {
          view.$refs.mainView.scrollTop = 0;
        });
        view.$helpers.success("Gespeichert", "Durchnittswerte wurden erfolgreich gespeichert.");
      }).catch(function (error) {
        console.log(error);
        view.employeeArray.forEach(employee => {
          if(employeeIdsToSave.includes(employee.id)){
            employee.saveRunning = false;
          }
        });
        view.importStage = 0;
        view.loading = 'finished';
        view.$forceUpdate();
        this.$nextTick(() => {
          view.$refs.mainView.scrollTop = 0;
        });
        view.$helpers.error("Error", "Fehler beim Speichern der Durchschnittswerte.");
      });
    },
  },
};
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>
.name-filter .fa-search {
  color: var(--contrast-4);
  position: absolute;
  top: 5px;
  left: 5px;
}

.half-width {
  width: 50%;
}

.fix-button-width {
  width: 150px;
}

.filter-input {
  margin-left: -45px;
  padding-left: 40px;
  width: calc(100% - 25px);
}

.list {
  width: calc(100% - 78px);
  margin: auto;
  height: 265px;
  box-shadow: inset 0 0 0 1px var(--contrast-3);
  overflow: hidden;
  position: absolute;
  border-radius: 5px;
}
.list .scroll-content {
  padding: 5px;
  overflow-y: auto;
  height: 265px;
}

.list-entry {
  height: 35px;
  margin-bottom: 2px;
  border-radius: 100px;
  cursor: pointer;
}

.list-entry:hover {
  background: var(--sub-menu);
  cursor: pointer;
}
.list-entry.selected {
  background: #e51550;
  cursor: pointer;
}
.list-entry.selected > .label,
.list-entry:hover > .label {
  color: var(--bg);
  cursor: pointer;
}
.list-entry.selected > .label > .label-small,
.list-entry.hover > .label > .label-small {
  color: var(--bg) !important;
  cursor: pointer;
}

.list-entry > .label {
  float: left;
  font-size: 14px;
  font-family: DistrictProBook;
  color: var(--contrast-4);
  padding-top: 6px;
  cursor: pointer;
}
.list-entry > .label > .label-small {
  cursor: pointer;
  color: var(--contrast-5);
}

.picture {
  float: left;
  border-radius: 100px;
  background: var(--contrast-1);
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 10px;
  height: 25px;
  width: 25px;
}
.position-right {
  float: right;
  margin: 0;
  margin-right: -5px;
}
.pictureImg {
  border-radius: 100px;
  height: 25px;
  width: 25px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--contrast-1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--contrast-3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa;
}
.has-header > .has-header > .scrollbar-container {
  height: calc(100% - var(--header-height) - var(--tab-menu-height)) !important;
  overflow-x: hidden;
  top: var(--header-height);
  padding: 0px 14px;
}

.selected {
  background-color: rgb(229, 21, 80);
}

.unselected {
  background-color: #888;
}
.btn-disabled {
  color: #ccc !important; /* Lighter text color */
  border-color: #ccc !important; /* Lighter border color */
  background-color: #f9f9f9 !important; /* Lighter background color */
  cursor: not-allowed !important; /* Cursor indicating non-interactivity */
  pointer-events: none; /* Prevents click events */
}

.import-modal {
  filter: none !important;
  position: absolute;
  box-sizing: content-box;
  top: 50;
  left: 50;
  width: 100%;
  height: 400px;
  border-radius: 5px;
  background: var(--bg);
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}
.dropdown-search.filters {
  height: 18px !important;
  display: block;
  margin-top: -4px;
  width: 100%;
}

.importedEmployeesInStoreWithAverages{
  background: lightsalmon;
}
.importedEmployeesInStoreWithAverages > td,
.importedEmployeesInStoreWithAverages > th{
  color: #404040 !important;
}
.storeEmployeesWithoutImportEntry{
  background: lightsalmon;
}
.storeEmployeesWithoutImportEntry > td,
.storeEmployeesWithoutImportEntry > th{
  color: #404040 !important;
}
.importedEmployeesInDifferentStore{
  background: pink;
}
.importedEmployeesInDifferentStore > td,
.importedEmployeesInDifferentStore > th{
  color: #404040 !important;
}
.importedEmployeesWarning1{
  background: pink;
}
.importedEmployeesWarning1 > td,
.importedEmployeesWarning1 > th{
  color: #404040 !important;
}
.importedEmployeesWarning3{
  background: lightsalmon;
}
.importedEmployeesWarning3 > td,
.importedEmployeesWarning3 > th{
  color: #404040 !important;
}
.importedEmployeesWarning4{
  background: pink;
}
.importedEmployeesWarning4 > td,
.importedEmployeesWarning4 > th{
  color: #404040 !important;
}

</style>
